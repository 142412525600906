<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <div class="d-flex align-center">
      <page-title title="Manage Offers" :badge="this.total" />
    </div>
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col sm="12" md="3" cols="12" class="d-flex align-start" style="gap: 0.5rem">
        <v-sheet
          key="sheets-status"
          elevation="2"
          height="32"
          rounded
          class="d-inline-flex align-center justify-center pl-3 pr-1"
        >
          <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4>
          <v-btn-toggle
            class="ml-2"
            v-model="offerState"
            mandatory
            color="deep-purple"
            dense
            group
            style="height: 32px"
            @change="stateChanged"
          >
            <v-btn
              :value="enums.ResourceOfferState.PendingManagement.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-gavel mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">
                Pending Management
              </span>
            </v-btn>
            <v-btn
              :value="enums.ResourceOfferState.Approved.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-check-double mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Approved </span>
            </v-btn>
            <v-btn
              :value="enums.ResourceOfferState.Rejected.value"
              color="white"
              class="mx-0"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-power-off mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Rejected </span>
            </v-btn>
            <v-divider vertical inset class="mx-2"></v-divider>
            <v-btn
              :value="enums.ResourceOfferState.Draft.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-pen mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Draft </span>
            </v-btn>
            <v-btn
              :value="enums.ResourceOfferState.PendingTarget.value"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-clock mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Pending Target </span>
            </v-btn>
            <v-btn
              :value="enums.ResourceOfferState.Declined.value"
              color="white"
              class="mx-0"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-ban mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">
                Declined By Target
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-btn
          color="deep-purple"
          dark
          @click="createOffer"
          v-if="$has(perms.ResourceOffers.CreateForOthers)"
        >
          <i class="far fa-plus mr-2"></i>New Offer
        </v-btn>
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <!-- <v-sheet
          height="28"
          width="1"
          color="blue-grey lighten-4"
          class="mx-1"
        ></v-sheet> -->
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-sheet elevation="2" height="32" rounded class="d-inline-flex" v-if="false">
          <v-btn-toggle v-model="tableMode" mandatory color="info" dense group>
            <v-btn :value="'list'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-list</v-icon>
            </v-btn>
            <v-btn :value="'cards'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-folders</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 resource-offers-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <code class="mono-font ml-2 secondary--text fs-12px blue-grey lighten-5" style="flex: none">
          #{{ item.id }}
        </code>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>

      <template v-slot:[`item.state`]="{ item }">
        <resource-offer-state small :state="item.state"></resource-offer-state>
      </template>

      <template v-slot:[`item.targetUser`]="{ item }">
        <div class="d-inline-flex">
          <user-avatar :user="item.targetUser" hide-roles disable-open-link></user-avatar>
        </div>
      </template>

      <template v-slot:[`item.sourceUser`]="{ item }">
        <div class="d-inline-flex">
          <user-avatar :user="item.sourceUser" hide-roles disable-open-link></user-avatar>
        </div>
      </template>

      <template v-slot:[`item.managementUser`]="{ item }">
        <user-avatar
          :user="item.managementUser"
          hide-roles
          disable-open-link
          show-no-user
          no-user-text="None"
        ></user-avatar>
      </template>

      <template v-slot:[`item.managementProcessed`]="{ item }">
        <v-chip
          v-if="item.managementProcessed"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="amber"
          text-color="gray darken-2"
        >
          <i class="far fa-check" style="line-height: 0"></i>
        </v-chip>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.managementProcessedDate`]="{ item }">
        <dater
          v-if="item.managementProcessed"
          :date="item.managementProcessedDate"
          dateonly
        ></dater>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.targetProcessed`]="{ item }">
        <v-chip
          v-if="item.targetProcessed"
          small
          x-small
          style="font-size: 11px; font-weight: 600"
          color="amber"
          text-color="gray darken-2"
        >
          <i class="far fa-check" style="line-height: 0"></i>
        </v-chip>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.targetProcessedDate`]="{ item }">
        <dater v-if="item.targetProcessed" :date="item.targetProcessedDate" dateonly></dater>
        <span v-else><i class="fad fa-clock fs-16px pl-2 text--disabled"></i></span>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <!-- <v-list-item
              @click="update(item.id)"
              v-if="$has(perms.ResourceOffers.Update)"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item @click="review(item.id)" v-if="$has(perms.ResourceOffers.View)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">fas fa-clipboard-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Review </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="displayActivityLogs(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">far fa-history</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary--text"> Activity Logs </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="del(item)" v-if="$has(perms.ResourceOffers.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-offer
      ref="editOffer"
      management-mode
      @close="onOfferClosed"
      @save="getData()"
    ></edit-offer>
    <review-offer
      ref="reviewOffer"
      management-mode
      @close="onOfferReviewClosed"
      @accept="getData()"
      @decline="getData()"
      @approve="getData()"
      @reject="getData()"
    ></review-offer>
    <offer-activity-log ref="offerActivityLog"></offer-activity-log>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import offersAPI from "../services/resource-offers-service";
import offersHeader from "../config/tables/manage-offers-header";
import ResourceOfferState from "../components/ResourceOfferState.vue";
import enums from "../../../plugins/enums";
import EditOffer from "../../ResourceOffers/components/EditOffer.vue";
import ReviewOffer from "../../ResourceOffers/components/ReviewOffer.vue";
import OfferActivityLog from "../components/activityLogs/OfferActivityLog.vue";

export default {
  data() {
    return {
      offerState: enums.ResourceOfferState.PendingManagement.value,
      laborTypesList: this.$options.filters.EnumToList(enums.LABOR_TYPE, true),
      perms: perms,
      enums: enums,
      storageKey: "ResourceOffersMyOffers",
      tableMode: "list",
      entities: [],
      selected: {},
      total: 0,
      resourceOfferTimerId: null,
      valid: false,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        state: enums.ResourceOfferState.PendingManagement.value,
        createdByIds: [],
        updatedByIds: [],
      },
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: offersHeader,
    };
  },
  props: {
    stage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.pq) this.options.search = this.$route.query.pq;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    window.addEventListener("popstate", this.checkRouteQuery, false);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
    window.removeEventListener("popstate", this.checkRouteQuery);
  },
  methods: {
    displayActivityLogs(item) {
      setTimeout(() => {
        this.$refs.offerActivityLog.open(item);
      });
    },
    onOfferSaved() {
      this.getData();
    },
    getPhaseRecord(record) {
      return (
        record.constructionPhase.name +
        " - " +
        this.getEnumMember(this.laborTypesList, record.laborType).text
      );
    },
    createOffer() {
      this.$refs.editOffer.open();
    },
    onOfferClosed() {
      const path = `/manage-offers`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onOfferReviewClosed() {
      const path = `/manage-offers`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    review(id) {
      this.$refs.reviewOffer.open(id);
    },
    // view(id) {
    //   setTimeout(() => {
    //     const path = `/manage-offers/${id}`;
    //     if (this.$route.path !== path) this.$router.push(path);
    //     this.$refs.editOffer.open(id, false);
    //   });
    // },
    // update(id) {
    //   setTimeout(() => {
    //     const path = `/manage-offers/${id}`;
    //     if (this.$route.path !== path) this.$router.push(path);
    //     this.$refs.editOffer.open(id, true);
    //   });
    // },
    rowClicked(row) {
      // this.view(row.id);
      this.review(row.id);
    },
    getDataDebounced() {
      this.$log("getDataDebounced");
      if (this.resourceOfferTimerId == null) {
        this.$log("START resourceOfferTimerId");
        this.resourceOfferTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.resourceOfferTimerId);

      // delay new call 400ms
      this.resourceOfferTimerId = setTimeout(() => {
        this.$log("resourceOfferTimerId");
        this.getData();
      }, 400);
    },
    getData() {
      this.$log("getData()", this.$clean(this.options, true));
      this.loadingStates.table = true;
      this.$backToTop(0, document.querySelector(".resource-offers-table .v-data-table__wrapper"));
      offersAPI
        .getOffersForManagement(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.pq, evt);
      if (this.$route.query.pq) this.options.search = this.$route.query.pq;
      else this.options.search = null;
    },
    del(offer) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Resource Offer?`,
          title: `Delete Resource Offer?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return offersAPI
                  .delete(offer.id)
                  .then(() => {
                    this.onResourceOfferDeleted(offer.id);
                    this.$dialog.notify.success("Resource Offer deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error(
                      "An error occurred during deleting the resource offer!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onResourceOfferDeleted(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    add() {
      this.modals.add = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
      });
      this.selected = {};
    },
    stateChanged() {
      var op = this.cloneDeep(this.options);
      op.page = 1;
      op.state = this.offerState;
      this.options = this.cloneDeep(op);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$log(">> options > watch handler");
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EditOffer, ReviewOffer, ResourceOfferState, OfferActivityLog },
};
</script>

<style lang="scss">
.resource-offers-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
